.products {
  margin: auto;
  padding-top: 100px;
  width: 90%;
  min-height: 70vh;
}

.products__title {
  color: var(--white-hover);
  min-width: 320px;
  max-width: 400px;
  font-size: 2.5rem;
  border-bottom: 2px solid var(--primary);
  padding-left: 10px;
  text-shadow: -2px 1px 1px var(--primary);
}

.products__list-c {
  margin: auto;
  display: flex;
  max-width: 1300px;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.products__card {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  /* border: 1px solid var(--primary); */
  -webkit-box-shadow: 2px 10px 13px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 13px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 10px 13px -7px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  min-height: 370px;
}

.ps-card__image {
  width: 200px;
}

.ps-card__line {
  width: 70%;
  height: 1px;
  background: var(--white-hover);
}

.ps-card__title {
  color: var(--primary);
  margin-top: 5px;
}

.ps-card__model {
  color: var(--dark-acent);
}

.ps-card__circle {
  width: 10px;
  height: 10px;
  background: var(--white-hover);
  border-radius: 50%;
  margin: 5px 0;
}

.ps-card__description {
  color: var(--dark-acent);
  font-size: 0.8rem;
}

.noresults__msg {
  color: var(--primary);
}

@media screen and (min-width: 768px) {
  .products__list-c {
    justify-content: center;
  }
}
