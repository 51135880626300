.modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

.modal.is-open {
    display: flex;
}

.modal__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    min-width: 300px;
    max-width: 800px;
}

.modal__btnClose {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    bottom: calc(100% - 15px);
    left: calc(100% - 15px);
    background: var(--primary);
    color: var(--white);
    cursor: pointer;
}

.modal__image {
    width: 100%;
    min-width: 300px;
    max-width: 800px;
}



@media screen and (min-width: 508px) {
    .vertical {
        max-width: 500px;
    }

}