.events {
    width: 100%;
    height: auto;
    background: var(--white-hover);
    padding: 50px 0;
    position: relative;
}

.rect1 {
    position: absolute;
    bottom: calc(100% - 145px);
    left: 0;
    width: 100%;
    z-index: 2;
}

.rect {
    position: absolute;
    top: calc(100% - 145px);
    left: 0;
    width: 100%;
    z-index: 2;
}

.intro__cont-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro__image {
    width: 300px;
}

.events__container {
    z-index: 15;
    padding: 80px 0;
}

.events__introduction {
    width: 90%;
    margin: auto;
    padding: 20px;
}

.introduction__title {
    color: var(--primary);
    margin-bottom: 15px;
    font-size: 3rem;
    text-shadow: 3px 3px 0 var(--dark-acent), 3px 3px 5px var(--dark-acent);
}



.introduction__text {
    color: var(--white);
    font-size: 1.2rem;
}

.events__feat {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 15px;
}

.events__card {
    width: 290px;
    height: auto;
    background-color: #9a8a7f92;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    text-align: left;
    -webkit-box-shadow: 7px 9px 16px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 7px 9px 16px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 7px 9px 16px -6px rgba(0, 0, 0, 0.75);
}


.e-card__contenido {
    padding: 10px 10px 0 10px;
}

.e-card__title {
    color: var(--primary);
    text-shadow: 3px 3px 0 var(--dark-acent), 3px 3px 5px var(--dark-acent);
}

.e-card__line {
    height: 2px;
    width: 100px;
    background: var(--primary);
    margin-bottom: 15px;
}

.e-card__text {
    color: var(--white);
    margin-bottom: 10px;
}

.e-card__imge {
    width: 290px;
    height: 155px;
    object-fit: cover;

}

.e-card__cont-img {
    width: 100%;
    height: 155px;
    position: relative;
}

.e-card__cont-imgfront {
    width: 100%;
    height: 100%;
    background: rgb(191, 157, 143, 0.3);
    position: absolute;

}



@media screen and (min-width: 768px) {

    .events {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .events__feat {
        grid-template-columns: 1fr 1fr;
        padding: 30px;
        gap: 35px;
    }

    .events__container {
        display: flex;
        max-width: 1300px;
    }
}