.header__section--primary {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: var(--primary);
}

.header__socials {
  display: flex;
  gap: 20px;
  align-items: center;
}


.socials__item {
  color: var(--white);
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}


.socials__item--med {
  font-size: 2rem;
}

.header__lang {
  display: flex;
  align-items: center;
  gap: 3px;
  border: 1px solid var(--white);
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  transition: all 0.5s ease;
}

.socials__item:hover {
  color: var(--white-hover);
}

.header__lang:hover {
  color: var(--white-hover);
  border: 1px solid var(--white-hover);
}

.header__section--secondary {
  background: var(--transparent-dark);
  width: 100%;
  position: fixed;
  z-index: 100;
}

.sticky {
  top: 0;
}

.navbar__img {
  width: 150px;
  height: 33px;
}

.navbar {
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  align-items: center;
  height: 60px;
}

.navbar__list {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  flex-direction: column;
  background: var(--transparent-dark);
  transition: all 1s ease;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.list__item {
  color: var(--white);
  width: 110px;
  text-align: center;
  padding: 5px 2px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.list__item--drop {
  color: var(--white);
  width: 110px;
  text-align: center;
  padding: 5px 2px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.item__label {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.item__dropdown {
  padding: 5px;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  display: none;
  transition: all 1s ease;
}

.item__link-drop {
  color: var(--white);
  transition: all 0.5s ease;
}

.item__link-drop:hover {
  color: var(--primary)
}

.show--menu {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.show--drop {
  display: block;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.dropdown__item {
  padding: 2px 5px;
  transition: all 0.5s ease;
}

.list__item--drop:hover {
  border-bottom: 2px solid var(--primary);
}

.list__item--drop:hover .item__label {
  color: var(--primary);
}

.list__item:hover {
  border-bottom: 2px solid var(--primary);
}

.dropdown__item:hover {
  color: var(--primary);
}

.navbar__menu {
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  color: var(--white);
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.navbar__menu:hover {
  background: var(--primary);
}

.item__link {
  color: var(--white);
  transition: all 0.5 ease;
}

.list__item:hover .item__link {
  color: var(--primary);
}

.form__input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__input {
  width: 150px;
  height: 25px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0 5px 0 15px;
}

.search__btn {
  height: 27px;
  width: 35px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--primary);
  color: var(--white);
  cursor: pointer;
}

/* ==================== Media query ============================ */

@media screen and (min-width: 768px) {
  .navbar__menu {
    display: none;
  }

  .navbar__list {
    clip-path: none;
    width: auto;
    position: static;
    flex-direction: row;
    background: transparent;
  }

  .item__dropdown {
    position: absolute;
    clip-path: none;
    display: block;
    background: var(--transparent-dark);
    top: 47px;
    display: none;
    width: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .list__item--drop {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .show--drop {
    display: block;
    /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); */
  }

  .search__input {
    width: 200px;
  }
}