.gallery {
    margin: 100px 0;
}

.gallery__info {
    width: 90%;
    margin: auto auto 30px auto;
    text-align: center;

}

.g-info__title {
    color: var(--primary);
    font-size: 3rem;
    margin-bottom: 15px;
    text-shadow: 2px 1px 0 var(--white-hover), 3px 2px 4px var(--dark-acent);
}

.g-info__text {
    color: var(--white-hover);
}

.gallery__grid {
    display: grid;
}

.gallery__grid {
    margin: auto;
    width: 90%;
    max-width: 1028px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(190px, auto);
    gap: 5px;
    padding: 20px;
    grid-auto-flow: dense;
}


.g-grid__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #03afff;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
}

.tall {
    grid-row: span 2;
}

.item__front {
    width: 100%;
    height: 100%;
    background: rgba(54, 14, 14, 0.623);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.i-front__cont {
    padding: 10px;
}

.i-front__title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--white-hover);
    /* text-shadow: 1px 1px 1px rgb(89, 85, 85, 0.7); */
}

.i-front__text {
    color: var(--white);
    /* text-shadow: 2px 1px 0 #000; */
}

.i-front__touch {
    position: absolute;
    color: var(--white);
    font-size: 3rem;
    top: calc(100% - 50%);
    left: calc(100% - 60%);
    opacity: 0;
    transition: all 0.5s ease;
}

.g-grid__item:hover {
    transform: scale(1.05);
}

.g-grid__item:hover .i-front__touch {
    opacity: 1;
}