.hero {
  width: 100%;
  height: 100vh;
  position: relative;
}

.hero__img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 48%;
}

.hero__content {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.hero__info {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hero__text {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero__text h2 {
  max-width: 600px;
  font-size: 2rem;
  text-align: center;
  color: var(--white);
  line-height: 62px;
  font-weight: 500;
  text-shadow: 3px 2px 0 #969696, 1px -4px 6px #aba8a8;
}

.text__patches {
  width: 100%;
  max-width: 700px;
}

.hero__logo {
  margin-top: 30px;
  width: 150px;
}

.hero__btn {
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 30px;
  background: var(--primary);
  color: var(--white);
  cursor: pointer;
  transition: all 0.5s ease;
}

.hero__btn-link {
  color: var(--white);
}

.hero__btn:hover {
  background: var(--white-hover);
}

/* ==================== Media query ============================ */

@media screen and (min-width: 768px) {
  .hero__info {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .hero__text h2 {
    font-size: 2.7rem;
  }

  .text__patches {
    width: 700px;
  }
}
