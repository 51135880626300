.contact {
    width: 90%;
    margin: 50px auto;
}

.contact__title {
    font-size: 3rem;
    color: var(--dark-acent);
    text-shadow: 1px 1px 1px var(--primary);
}

.contact__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
}

.c-content__form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
}

.c-content__form input {
    height: 30px;
    padding-left: 10px;
    border-radius: 10px;
    border: 1px solid var(--white-hover);
}

.c-content__form textarea {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--white-hover);
}

.form__c-check {
    padding-left: 5px;
    display: flex;
    gap: 10px;
}

.form__c-check label {
    font-size: 0.9rem;
}

.c-content__form button {
    width: 100px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--primary);
    color: var(--white);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.5s ease;
}

.c-content__form button:hover {
    color: var(--dark-acent);
}

.terms__links {
    color: var(--dark-acent);
}

.terms__links:hover {
    color: var(--primary);
}

.contact__btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    gap: 20px;
}

.btns__c-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: centers;
}

.btns__image {
    width: 300px;
}

.btns__c-btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.btns__btn {
    background-color: var(--primary);
    width: 350px;
    height: 90px;
    clip-path: polygon(14% 0%, 100% 0%, 88% 100%, 0% 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.b-btn__icon {
    width: 60px;
    background: var(--dark-acent);
    padding: 5px;
    border-radius: 5px;
    color: var(--white);
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.b-btn__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 22px;
}

.text__title {
    color: var(--dark-acent);
    font-weight: 500;
    font-size: 1.2rem;
}

.text__info {
    color: var(--white-hover);
}

.form__messageSend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form__loader {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    display: none;
}

.form__sendSucces {
    /* background: var(--dark-acent); */
    padding: 5px 10px;
    /* border-radius: 10px; */
    /* color: var(--primary); */
    display: none;
}

.show {
    display: block;
}