.distributors {
    width: 100%;
    background-color: var(--dark-acent);
    padding: 20px 0;
    position: relative;
}

.dis__title {
    position: relative;
    text-align: center;
    z-index: 99;
}

.dis__title span {
    color: var(--primary);
    font-size: 1.7rem;
    font-weight: 500;
    z-index: 10;
}

.dis__title h2 {
    margin-top: 50px;
    color: var(--white);
    font-size: 2rem;
    z-index: 100;

}

.dis__list {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
    gap: 15px;
}

.dis__card {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


.image__logos {
    width: 200px;
}

.sesgoArriba {
    position: absolute;
    top: 0;
    left: 0;
    border-width: 10vh 100vw 0 0;
    border-style: solid;
    border-color: var(--white) transparent transparent transparent;
    z-index: 1;
}