.footeR {
    width: 100%;
    background: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.footer__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: 40px;
}

.footer__c-imge {
    width: 100%;
    max-width: 350px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
}

.footer__img {
    width: 350px;
    z-index: 3;
    position: relative;
}

.back__fig {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 150px;
    z-index: 2;
    background-color: var(--dark-acent);
}

.footer__links {
    text-align: center;
}

.links__title {
    font-size: 1.5rem;
    margin-bottom: 5px;
    color: var(--white);
}

.link__item a {
    color: var(--white-hover);
    transition: all 0.5s ease;
}

.link__item a:hover {
    color: var(--white);
}

.copy {
    width: 90%;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    color: var(--white-hover);
}

.footer__pol {
    padding-right: 10px;
    border-right: 1px solid var(--white);
}

.footer__pol a,
.footer__pol2 a {
    color: var(--white-hover);
    transition: all 0.5 ease;
}

.footer__pol a:hover,
.footer__pol2 a:hover {
    color: var(--white);
}

.footer__pol2 {
    padding-left: 10px;
    border-left: 1px solid var(--white);
}


@media screen and (min-width: 605px) {

    .footer__content {
        width: 70%;
        justify-content: right;
    }

    .footer__links {
        text-align: right;
    }
}