.featProducts {
  width: 80%;
  margin: auto;
}


.featProducts__title {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  padding: 50px 0;
  color: var(--primary);
  text-shadow: 3px 3px 0 var(--white-hover), 3px 3px 5px var(--dark-acent);
}


.fearProducts__list {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  overflow: scroll;
  /* justify-content: center; */
  gap: 25px;
  flex-wrap: nowrap;
}

.prueba {
  margin: 0 0 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-width {
  width: 100%;
}

.slick-next::before,
.slick-prev::before {
  color: var(--primary) !important;
}

.slick-dots {
  bottom: -50px;
}

.slick-dots li button::before {
  color: red;
}

.slick-dots li.slick-active button::before {
  color: brown;
}


.slick-center {
  transform: scale(1.3);
}

@media screen and (max-width: 768px) {
  .slick-center {
    transform: scale(1.1);
  }
}