.pcts__modal {
    width: 100%;
    padding: 30px;
    max-height: 75vh;
    background: var(--white);
    border-radius: 10px;
    overflow-y: scroll;
}

.pts-modal__images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
}

.p-m-images__zoom {
    width: 250px;
    height: 264px;
    display: flex;
    height: auto;
    cursor: zoom-in;
    border-radius: 10px;
    border: 2px solid var(--white-hover);
}

.p-m-images__mainImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
    cursor: zoom-in;
    transition: opacity 0.5s;
}

.p-m-images__mainImage:hover {
    opacity: 0;
}

.p-m-images__thums {
    display: flex;
    gap: 10px;
}

.p-m-images__thum {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid var(--white-hover);
}

.pts-modal__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.p-m-content__title {
    margin-top: 5px;
    font-size: 1.5rem;
    color: var(--primary);
    border-bottom: 1px solid var(--white-hover);
    padding-bottom: 10px;
}

.p-m-content__description {
    color: var(--dark-acent);
    text-align: justify;
}

.p-m-c__list-item {
    list-style: disc;
    color: var(--dark-acent);
    margin-left: 20px;
}

@media screen and (min-width: 768px) {
    .pcts__modal {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }
}