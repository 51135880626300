.product__card {
    /* border: 1px solid #bb2424; */
    border-radius: 10px;
    width: 250px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.card__container-img {
    background: rgb(191, 157, 143);
    background: radial-gradient(circle, rgb(129, 102, 89) 18%, rgb(59, 51, 51) 100%);
    padding: 30px;
    border-radius: 50%;
    -webkit-box-shadow: 2px 9px 14px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 9px 14px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 9px 14px -4px rgba(0, 0, 0, 0.75);
}

.card__image {
    width: 120px;
    height: 110px;
    object-fit: cover;
}

.card__title {
    margin: 10px 0;
    color: var(--primary);
}

.card__description {
    text-align: center;
    color: var(--dark-acent);
}

.d-content {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}