.demos {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}

.demos__shadow {
    width: 100%;
    height: 100%;
    padding: 50px 0;
    background: rgb(191, 157, 143);
    background: radial-gradient(circle, rgba(191, 157, 143, 0.30575980392156865) 18%, rgba(0, 0, 0, 0.7679446778711485) 100%);
}

.demos__card {
    width: 340px;
    background-color: rgba(45, 40, 38, 0.7);
    padding: 20px;
    border-radius: 20px;
}

.demos__content {
    width: 90%;
    margin: 0 auto;
}

.de-content__title {
    color: var(--dark-acent);
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 1px 1px 0 #000;
    margin-bottom: 30px;
    text-align: center;
    background: rgb(177, 25, 25);
    background: radial-gradient(circle, rgba(177, 25, 25, 0.2777485994397759) 18%, rgba(0, 0, 0, 0.7679446778711485) 100%);
}

.de-card__title {
    color: var(--white-hover);
    text-shadow: 1px 1px 0 rgb(0, 0, 0, 0.411);
    font-size: 1.5rem;

}

.de-card__date {
    color: var(--dark-acent);
    font-size: 1.2rem;
}

.de-card__description {
    color: var(--white);
    margin: 10px 0 20px 0;
}

.de-content__list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
}